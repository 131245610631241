
 .spinner{
    margin: 0px;
    margin-left: calc(50% - 10px);;
    margin-top: 20px;
    border-radius:10px;
    
}

.buttonClass{
    position: absolute;
    bottom: 5px;
    width: 100%;
    
}
.column{
  padding:10%;
  box-sizing:border-box;
  width:90%;
  margin: 20 auto;
  box-shadow: 4px 4px 8px rgb(121, 104, 104);
  margin-bottom:45px
}

.report {
  width: 70vw;
  overflow: auto;
}

.reporttwo {
  width: 85vw;
  overflow: auto;
}

.procon {
  width: 70vw;
  overflow: auto;
}
/*clear-fix the parent of column because it collapsed!  */
form:after{
  content:'';
  display:block;
  clear:both;
}

/* FORM STUFF BEGINS HERE */
form{
  font-size:110%;
  margin-bottom:45px
  
}
label{
  display:block;
  color:rgb(214, 214, 239);
  margin:6px 0 1px;
}
fieldset label{
  display:inline-block;
  font-size:85%;
  font-family: Roboto;
}


input[type=text],
input[type=email],
input[type=tel],
textarea,
select{
  width:100%;
  padding:15px;
  box-sizing:border-box;
  background-color:rgba(211, 220, 222, 0.308);
  border: solid 1px black;
  font-family:'Lucida Roboto', monospace;
  margin-bottom:15px
}
textarea{
  resize: vertical;
  min-height: 70px;
  
}
fieldset{
  border:none;
  margin:20px 0 ;
  padding:10px 0;
  border-bottom: 1px solid rgb(12, 12, 11);
}
legend{
  color:white;
  background-color:rgb(12, 12, 11);
  display:block;
  width:100%;
  padding:3px;
}

/* BUTTONS */
button[type=submit]{
  background-color:black;
  color:white;
  border:none;
  padding: 6px 20px;
  font-size:110%;
  border-radius:10px;
  font-family: Roboto;
  margin: 0px;
  margin-left: 50%;
  margin-bottom: 10px;
  transform: translateX(-50%)
}

button:focus {
    background: rgb(48, 46, 46);
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
       -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
            box-shadow: inset 0px 0px 5px #c1c1c1;
     outline: none;
  }

  button[type=reset]{
    background-color:rgb(238, 240, 240);
    color:black;
    border:none;
    padding: 6px 20px;
    font-size:110%;
    border-radius:10px;
    font-family: Roboto;
    margin: 0px;
    margin-left: 50%;
    transform: translateX(-50%)
  }
  
  button:focus {
      background: rgb(48, 46, 46);
      -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
         -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
              box-shadow: inset 0px 0px 5px #c1c1c1;
       outline: none;
    }
/* hovers and tabs */
input[type=text]:hover,
input[type=email]:hover{
  background-color:rgb(187, 219, 235);
  
}
input[type=text]:focus,
input[type=email]:focus{
  outline: 2px solid rgb(179, 219, 213);
}

/* responsive bonus round */
@media screen and (min-width:450px){
/*  2col  */
  .column{
    width:50%;
    float:left;
    padding:3%;
    box-sizing:border-box;
  }
}






